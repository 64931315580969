.video-call-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #1a1a1a;
  color: white;


.remote-video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  padding: 1rem;
  flex: 1;
}

.remote-video-container {
  aspect-ratio: 16 / 9;
  background-color: #2a2a2a;
  border-radius: 8px;
  overflow: hidden;
}

.local-video-container {
  position: absolute;
  bottom: 80px;
  right: 20px;
  width: 200px;
  aspect-ratio: 16 / 9;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.controls-toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.control-btn {
  background-color: #4a4a4a;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 0 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.control-btn:hover {
  background-color: #5a5a5a;
}

.control-btn.end-call {
  background-color: #ff4136;
}

.control-btn.end-call:hover {
  background-color: #ff5a4f;
}

.recording-indicator {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: rgba(255, 0, 0, 0.7);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .remote-video-grid {
    grid-template-columns: 1fr;
  }

  .local-video-container {
    width: 120px;
    bottom: 100px;
  }

  .control-btn {
    width: 40px;
    height: 40px;
  }
}
}